<template>
  <div>
    <HeadVue></HeadVue>
    <router-view></router-view>
    <FooterVue :address='address' :siteID='siteID' :siteName='siteName'></FooterVue>
  </div>
</template>

<script>
import HeadVue from '@/components/Head.vue';
import FooterVue from '@/components/Footer.vue';
export default {
  data(){
    return{
      title:'',
      keyWords:'',
      description:``,
      address:'',
      siteID:'',
      siteName:'',
    }
  },

   metaInfo() {
    return {
      title: this.title, // set a title
      meta: [
        {
          // set meta
          name: "keyWords",
          content: this.keyWords,
        },
        {
          name: "description",
          content: this.description,
        },
      ],
    };
  },

  components:{
    HeadVue,
    FooterVue,
  },

  mounted() {
    let that = this;
    //获取站点信息
    that.$axios.get('site/get_site_newList?cid=11&page=1&limit=5').then(res=>{
      that.title = res.data.data.list[0].title || '';//站点名称
      that.keyWords = res.data.data.list[1].title || '';//站点关键词
      that.address = res.data.data.list[2].title || '';//公司地址
      that.siteID = res.data.data.list[3].title || '';//站点备案号
      that.siteName = res.data.data.list[4].title || '';//集运名称
    })
  },


};
</script>


<style>

:root {
	--theme-color:#4284DC;/* 主题色 */
	--theme-color-rgba:66,132,220;/* 主题色rgb */
	--theme-font-color-bg : #E5CD52; /*字体背景颜色*/
	--theme-color-bg:#4284DC;/* 主题背景色 */
  --theme-color-title:#4284DC; /*主题标题字体 */
}


.theme-color-border{
  border:1px solid var(--theme-color-bg) !important;
}
.theme-color{
	color: var(--theme-color) !important
}
.theme-color-bg{
	background-color: var(--theme-color-bg) !important;
}
.them-color-font-bg{
	background-color: var(--theme-font-color-bg) !important;
}
.theme_tips{
	color: var(--theme_tips) !important
}
.theme_tips_bg{
	background-color: var(--theme_tips_bg) !important
}
p{margin:0}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}







</style>
